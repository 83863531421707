import React from "react";
import {Theme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import {CustomAppBar} from "../CustomAppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import {Drawer, DrawerHeader} from "../drawer";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import {DrawerListItemButton} from "../DrawerListItemButton";
import {Footer} from "../Footer";
import {IPageData} from "../../logic/consts/PagesDataConsts";
import {Link, useNavigate} from "react-router-dom";
import {Container} from "@mui/material";
import logo from "../../assets/images/logo.png";
import {useMediaQuery} from 'react-responsive';

interface ICustomPageContainerProps {
    currentPageName: string
    theme: Theme
    open: boolean
    handleDrawerOpen: () => void
    handleDrawerClose: () => void
    drawerItemsLists: IPageData[][]
    content: JSX.Element
}

export const CustomPageContainer: React.FC<ICustomPageContainerProps> = (props) => {
    const {
        currentPageName,
        theme,
        open,
        handleDrawerOpen,
        handleDrawerClose,
        drawerItemsLists,
        content
    } = props

    const navigate = useNavigate()
    const isMobile = useMediaQuery({query: `(max-width: 760px)`});

    const date = new Date()
    const year = date.getFullYear()

    return (
        <>
            <Container style={{
                maxWidth: "1500px",
            }}>
                <Box sx={{display: 'flex'}}>
                    <CssBaseline/>
                    <CustomAppBar position="fixed" open={open}>
                        <Toolbar
                            sx={{
                                height: 56
                            }}
                        >
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                sx={{
                                    marginRight: 5,
                                    ...(open && {display: 'none'}),
                                }}
                            >
                                <MenuIcon/>
                            </IconButton>
                            {/*<Typography variant="h6" component="div" sx={{flexGrow: 1}}>*/}
                            {/*    /!*{currentPageName}*!/*/}
                            {/*</Typography>*/}
                            <Link to={'/'}>
                                <img
                                    src={logo}
                                    alt="Logo"
                                    // onClick={() => navigate(PagesDataConsts.HOME.route)}
                                    // width="100vw"
                                    style={{
                                        // maxWidth: "100%",
                                        height: 56,
                                        width: 'auto',
                                        // resize:"hor",
                                    }}

                                />
                            </Link>
                            <Typography sx={{flexGrow: 1}}/>
                            {open && isMobile ? null :
                                <Typography variant="h6" component="div">Zadzwoń: 601507454 lub 515165676</Typography>
                            }
                        </Toolbar>
                    </CustomAppBar>
                    <Drawer variant="permanent" open={open}>
                        <DrawerHeader>
                            <IconButton onClick={handleDrawerClose}>
                                {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                            </IconButton>
                        </DrawerHeader>
                        {drawerItemsLists.map((itemsList) =>
                            <>
                                <Divider/>
                                <List>
                                    {itemsList.map((entry) =>
                                        <DrawerListItemButton
                                            key={entry.name}
                                            open={open}
                                            name={entry.name}
                                            icon={entry.icon}
                                            textProps={entry.textProps}
                                            selected={entry.name === currentPageName}
                                            onClick={() => navigate(entry.route)}
                                        />
                                    )}
                                </List>

                            </>
                        )}
                    </Drawer>
                    <Box component="main" sx={{flexGrow: 1, p: 3}}>
                        <DrawerHeader/>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <Typography
                                fontWeight={600}
                                sx={{
                                    fontSize: {sm: 50, xs: 30}
                                }}
                            >{currentPageName}</Typography>
                        </div>
                        {content}
                    </Box>
                </Box>
            </Container>
            <Footer>
                <Typography fontSize={12}>
                    Copyright © 2022 - {year} JDK Klimatyzacja Wszystkie prawa zastrzeżone
                </Typography>
            </Footer>
        </>
    );
}