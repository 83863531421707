import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import {MapContainer, Marker, MarkerProps, Popup, TileLayer} from 'react-leaflet';
import {Marker as MarkerType, LatLngExpression} from 'leaflet'
import React, {useEffect, useRef} from "react";
import {Container, IconButton, Tooltip} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconAnchor: [12, 41]
});

L.Marker.prototype.options.icon = DefaultIcon;

const companyLocation: LatLngExpression = [50.29098437938852, 18.883810167587505]

const MyMarker: React.FC<MarkerProps> = (props) => {
    const markerRef = useRef<MarkerType>(null)

    useEffect(() => {
        if (markerRef.current) {
            markerRef.current.openPopup();
        }
    }, []);

    return (
        <Marker {...props} ref={markerRef}>
            <Popup
                offset={[0, -30]}
                closeOnClick={false}
                closeButton={false}
            >
                JDK Klimatyzacja
                <br/>
                Traugutta 54A
                <br/>
                42-674 Zbrosławice
                <br/>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Tooltip title='Otwórz w Google Maps'>
                        <IconButton
                            href={
                                'https://www.google.com/maps/search/?api=1&query=Traugutta+54A%2C+42%2D674+Zbrosławice'
                            }
                            target="_blank" rel="noopener noreferrer">
                            <OpenInNewIcon/>
                        </IconButton>
                    </Tooltip>
                </div>
            </Popup>
        </Marker>
    );
}

export const ContactsMap: React.FC = () => {

    return (
        <Container
            sx={{
                maxWidth: 1500,
                height: {sm: 400, xs: 250},
            }}
        >
            <MapContainer center={companyLocation} zoom={9} scrollWheelZoom={true}
            >
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <MyMarker position={[50.428010616979135, 18.74738698650624]}/>
            </MapContainer>
        </Container>
    )
}