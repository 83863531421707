import {Card, CardHeader, CardMedia, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {CardProps} from "@mui/material/Card/Card";

export interface ICompanyCardProps extends CardProps {
    imagePath: string
    title?: string
    subheader?: string
    url: string
}

export const CompanyCard: React.FC<ICompanyCardProps> = (props) => {
    const {imagePath, title, subheader, url, ...cardProps} = props;

    return (
        <Card
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            sx={{
                maxWidth: {lg: 300, md: 300, sm: 250, xs: 250},
                height: {xs: 250},
                width: '100%'
            }}
            {...cardProps}
        >
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                width: '100%',
                textAlign: 'center',
            }}>
                <CardMedia
                    style={{
                        objectFit: 'contain',
                    }}
                    component="img"
                    image={imagePath}
                    title={title}
                    alt="Brak obrazka"
                    sx={{
                        maxWidth: {md: 250, sm: 200, xs: 161},
                        height: 'auto',
                    }}
                />

                <CardHeader
                    title={title}
                    action={
                        <Tooltip title='Otwórz na stronie sprzedawcy'>
                            <IconButton href={url} target="_blank" rel="noopener noreferrer">
                                <OpenInNewIcon/>
                            </IconButton>
                        </Tooltip>
                    }
                />
            </div>
        </Card>
    )
}