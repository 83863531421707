import React from "react";

const phantom = {
    display: 'block',
    padding: '20px',
    height: '60px',
    width: '100%',
}

interface IFooterProps {
    children?: JSX.Element
}

export const Footer: React.FC<IFooterProps> = (props) => {
    const {children} = props

    return (
        <>
            <div style={phantom}/>
            <div style={{
                backgroundColor: "#F8F8F8",
                borderTop: "1px solid #E7E7E7",
                textAlign: "center",
                padding: "20px",
                position: "fixed",
                left: "30px",
                bottom: "0",
                height: "60px",
                minWidth: "60px",
                width: "100%",
            }}>
                {children}
            </div>
        </>
    )
}

// export default Footer
