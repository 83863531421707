import React from "react";
import ModalImage from "react-modal-image";
import {useMediaQuery} from "react-responsive";

interface IZoomableImageProps {
    smallImagePath: string
    largeImagePath: string
}

export const ZoomableImage: React.FC<IZoomableImageProps> = (props) => {
    const {smallImagePath, largeImagePath} = props

    const isMobile = useMediaQuery({query: `(max-width: 760px)`});

    const nonMobileImage =
        <ModalImage
            small={smallImagePath}
            large={largeImagePath}
            hideDownload={true}
            alt='Certyfikat'
        />

    const mobileImage =
        <img
            src={largeImagePath}
            alt="Certyfikat"
            style={{
                maxWidth: "100%",
                height: "auto",
                width: "auto/9"
            }}
        />

    const content = isMobile ? mobileImage : nonMobileImage

    return (
        content
    )
}