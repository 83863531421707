import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from "@mui/icons-material/Info";
import EngineeringIcon from '@mui/icons-material/Engineering';
// import ChaletIcon from '@mui/icons-material/Chalet';
import AcUnitIcon from "@mui/icons-material/AcUnit";
import BadgeIcon from '@mui/icons-material/Badge';
import PhoneIcon from "@mui/icons-material/Phone";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import HvacIcon from '@mui/icons-material/Hvac';
import HeatPumpIcon from '@mui/icons-material/HeatPump';
import React from "react";
import {SvgIconProps} from "@mui/material/SvgIcon/SvgIcon";
import {TypographyProps} from "@mui/material/Typography/Typography";

export interface IPageData {
    name: string
    route: string
    icon: React.ReactElement<SvgIconProps>
    textProps?: TypographyProps
}

export class PagesDataConsts {
    static readonly HOME: IPageData = {name: 'Strona główna', route: '/', icon: <HomeIcon color='primary'/>, textProps: {fontWeight: 600}}
    static readonly ABOUT: IPageData = {name: 'O nas', route: '/o_nas', icon: <InfoIcon color='primary'/>, textProps: {fontWeight: 600}}
    static readonly SERVICES: IPageData = {name: 'Usługi', route: '/uslugi', icon: <EngineeringIcon color='primary'/>, textProps: {fontWeight: 600}}
    static readonly RANGE: IPageData = {name: 'Oferta', route: '/oferta', icon: <LocalOfferIcon color='primary'/>, textProps: {fontWeight: 600}}
    static readonly CERTIFICATES: IPageData = {name: 'Certyfikaty', route: '/certyfikaty', icon: <BadgeIcon color='primary'/>, textProps: {fontWeight: 600}}
    static readonly CONTACT_US: IPageData = {name: 'Kontakt', route: '/kontakt', icon: <PhoneIcon color='primary'/>, textProps: {fontWeight: 600}}

    // Services routes
    static readonly AIR_CONDITIONING: IPageData = {name: 'Klimatyzacja', route: '/klimatyzacja', icon: <AcUnitIcon color='primary'/>}
    static readonly VENTILATION: IPageData = {name: 'Wentylacja', route: '/wentylacja', icon: <HvacIcon color='primary'/>}
    static readonly HEAT_PUMPS: IPageData = {name: 'Pompy ciepła', route: '/pompy_ciepla', icon: <HeatPumpIcon color='primary'/>}
}