import React from "react";
import {CustomPage} from "../../components/custom_page/CustomPage";
import {Grid} from "@mui/material";
import {PagesDataConsts} from "../../logic/consts/PagesDataConsts";
import {INavigationBlockProps, NavigationBlock} from "../../components/NavigationBlock";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import HvacIcon from "@mui/icons-material/Hvac";
import HeatPumpIcon from "@mui/icons-material/HeatPump";


export const Range: React.FC = () => {
    const currentPageName = PagesDataConsts.RANGE.name

    const blocks: INavigationBlockProps[] = [
        {name: 'Klimatyzacja', route: PagesDataConsts.AIR_CONDITIONING.route, icon: <AcUnitIcon/>},
        {name: 'Wentylacja', route: PagesDataConsts.VENTILATION.route, icon: <HvacIcon/>},
        {name: 'Pompy ciepła', route: PagesDataConsts.HEAT_PUMPS.route, icon: <HeatPumpIcon/>},
    ]

    const content =
        <>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '60vh',
            }}>
                <Grid container justifyContent='center' spacing={{sm: 10, xs: 2}}>
                    {blocks.map((entry) => <NavigationBlock {...entry}/>)}
                </Grid>
            </div>
        </>

    return (
        <CustomPage
            currentPageName={currentPageName}
            content={content}
        />
    )
}