import React from "react";
import {CustomPage} from "../../components/custom_page/CustomPage";
import {PagesDataConsts} from "../../logic/consts/PagesDataConsts";
import {ZoomableImage} from "./ZoomableImage";
import ac_certification_img from "../../assets/images/ac_certification.jpg"
import ac_certification_1024p_img from "../../assets/images/ac_certification_1024p.jpg"

export const Certificates: React.FC = () => {
    const currentPageName = PagesDataConsts.CERTIFICATES.name

    const content =
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <ZoomableImage
                smallImagePath={ac_certification_1024p_img}
                largeImagePath={ac_certification_img}
            />
        </div>

    return (
        <CustomPage
            currentPageName={currentPageName}
            content={content}
        />
    )
}