export const aboutText: string[] = [
    'JDK jest firmą oferującą sprzedaż, montaż i serwis klimatyzatorów renomowanych firm tj: ALPICAIR, SINCLAIR, DAIKIN, MITSUBISHI, ROTENSO. Specjalizujemy się w sprzedaży, instalacji i serwisie klimatyzatorów typu split i multisplit na terenie woj. śląskiego i opolskiego. Doświadczenie, wyszkolona kadra techniczna oraz profesjonalna obsługa klienta wyznacza naszą pozycję na rynku. Jakość w naszej firmie jest priorytetem.',
    'Dbamy o różnorodność naszej oferty dzięki temu znajdą Państwo rozwiązania perfekcyjnie dopasowane do swoich potrzeb. Nasza oferta obejmuje klimatyzatory sprawdzonych marek,  gwarantując Państwu najwyższej jakości.'
]


export const advantagesList: string[] = [
    'brak ukrytych kosztów – wycena obejmuje wszystkie koszty;',
    'darmowa wycena – dobierzemy odpowiedni system;',
    'wykwalifikowany zespół – wieloletnie doświadczenie oraz wszystkie wymagane uprawnienia;',
    'niezawodne urządzenia – oferujemy produkty tylko najwyższej jakości;',
    'wysoka jakość i estetyka wykonania;',
    'montaż w jeden dzień - w przypadku instalacji jednoetapowych;',
    'szybkie terminy realizacji.',
]
