import {Paragraph} from "./Paragraph";

export const PageInProgress: React.FC = () => {
    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '80vh',
        }}>
            <Paragraph fontSize={35} textAlign='center'>
                Strona dostępna wkrótce...
            </Paragraph>
        </div>
    )
}