import {createContext, useState} from "react";

export const DrawerOpenedContext = createContext({
    drawerOpened: false, openDrawer: () => {
    }, closeDrawer: () => {
    }
})

export const DrawerOpenedProvider = ({children}: any) => {

    const [drawerOpened, setDrawerOpened] = useState(false)

    const openDrawer = () => {
        setDrawerOpened(true)
    }
    const closeDrawer = () => {
        setDrawerOpened(false)
    }
    const value = {
        drawerOpened: drawerOpened,
        openDrawer: openDrawer,
        closeDrawer: closeDrawer
    }


    return (
        <DrawerOpenedContext.Provider value={value}>
            {children}
        </DrawerOpenedContext.Provider>
    )
}


