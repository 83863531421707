import {Route} from 'react-router-dom'
import {Home} from "../pages/home/Home";
import {About} from "../pages/about/About";
import {Range} from "../pages/range/Range";
import {Certificates} from "../pages/certificates/Certificates";
import {ContactUs} from "../pages/contact_us/ContactUs";
import {Services} from "../pages/service/Services";
import {PagesDataConsts} from "../logic/consts/PagesDataConsts";
import {AirConditioning} from "../pages/range/range_pages/AirConditioning";
import {Ventilation} from "../pages/range/range_pages/ventilation/Ventilation";
import {HeatPumps} from "../pages/range/range_pages/HeatPumps";

const routes = [
    <Route key={PagesDataConsts.HOME.route} path={PagesDataConsts.HOME.route} element={<Home/>}/>,
    <Route key={PagesDataConsts.ABOUT.route} path={PagesDataConsts.ABOUT.route} element={<About/>}/>,

    // Services routes
    <Route key={PagesDataConsts.SERVICES.route} path={PagesDataConsts.SERVICES.route} element={<Services/>}/>,
    <Route key={PagesDataConsts.AIR_CONDITIONING.route} path={PagesDataConsts.AIR_CONDITIONING.route}
           element={<AirConditioning/>}/>,
    <Route key={PagesDataConsts.VENTILATION.route} path={PagesDataConsts.VENTILATION.route} element={<Ventilation/>}/>,
    <Route key={PagesDataConsts.HEAT_PUMPS.route} path={PagesDataConsts.HEAT_PUMPS.route} element={<HeatPumps/>}/>,

    <Route key={PagesDataConsts.RANGE.route} path={PagesDataConsts.RANGE.route} element={<Range/>}/>,
    <Route key={PagesDataConsts.CERTIFICATES.route} path={PagesDataConsts.CERTIFICATES.route}
           element={<Certificates/>}/>,
    <Route key={PagesDataConsts.CONTACT_US.route} path={PagesDataConsts.CONTACT_US.route} element={<ContactUs/>}/>,
]

export default routes