import React from "react";
import Typography from "@mui/material/Typography";
import {TypographyProps} from "@mui/material/Typography/Typography";

interface IParagraphProps extends TypographyProps {
    children: React.ReactNode
}

export const Paragraph: React.FC<IParagraphProps> = (props) => {
    const {children, ...typographyProps} = props
    return (<Typography paragraph fontSize={20} {...typographyProps}>
        {props.children}
    </Typography>)
}