import React from "react";
import {Card, CardContent, CardHeader, CardMedia, Typography, useTheme} from "@mui/material";

export interface IACTypeDetailsCardProps {
    imagePath: string
    title?: JSX.Element
    subheader?: string
}

export const ACTypeDetailsCard: React.FC<IACTypeDetailsCardProps> = (props) => {
    const {imagePath, title, subheader, ...cardProps} = props;

    const theme = useTheme()

    return (
        <Card
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            sx={{
                maxWidth: {lg: 300, md: 300, sm: 250, xs: 250},
                height: {xs: 450},
                width: '100%'
            }}
            {...cardProps}
        >
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                width: '100%',
                textAlign: 'center',
            }}>
                <CardHeader
                    title={title}
                    sx={{color:theme.palette.primary.main}}
                />
                <CardMedia
                    style={{
                        objectFit: 'contain',
                    }}
                    component="img"
                    image={imagePath}
                    // title={title}
                    alt="Brak obrazka"
                    sx={{
                        maxWidth: {md: 250, sm: 200, xs: 161},
                        height: 200,
                        // height: 'auto',
                    }}
                />
                <CardContent>
                    <Typography color="text.secondary">
                        {subheader}
                    </Typography>
                </CardContent>

            </div>
        </Card>
    )
}