import React from "react";
import logo from "../../assets/images/logo.png";
import stages from "../../assets/images/stages.png";
import Typography from "@mui/material/Typography";
import {CustomPage} from "../../components/custom_page/CustomPage";
import {PagesDataConsts} from "../../logic/consts/PagesDataConsts";
import {Grid} from "@mui/material";
import {cities} from "./homeContent";
import {INavigationBlockProps, NavigationBlock} from "../../components/NavigationBlock";
import EngineeringIcon from "@mui/icons-material/Engineering";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import {Paragraph} from "../../components/Paragraph";

export const Home: React.FC = () => {
    const currentPageName = PagesDataConsts.HOME.name

    const blocks: INavigationBlockProps[] = [
        {name: PagesDataConsts.SERVICES.name, route: PagesDataConsts.SERVICES.route, icon: <EngineeringIcon/>},
        {name: PagesDataConsts.RANGE.name, route: PagesDataConsts.RANGE.route, icon: <LocalOfferIcon/>}
    ]

    const content =
        <>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                // height: '100vh',
            }}>
                <img
                    src={logo}
                    alt="Logo"
                    // width="100vw"
                    style={{
                        maxWidth: "100%",
                        height: "auto",
                        width: "auto/9"
                        // resize:"hor",
                    }}
                />
            </div>
            <Grid container justifyContent='center' spacing={{sm: 10, xs: 2}}>
                {blocks.map((entry) => <NavigationBlock {...entry}/>)}
            </Grid>
            <br/><br/>
            <Typography paragraph align={'center'}><h1>Etapy realizacji usługi</h1></Typography>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                // height: '100vh',
            }}>
                <img
                    src={stages}
                    alt="Etapy"
                    // width="100vw"
                    style={{
                        maxWidth: "100%",
                        height: "auto",
                        width: "auto/9"
                        // resize:"hor",
                    }}
                />
            </div>
            <br/>
            <Typography paragraph align={'center'}><h1>Miejsce działalności</h1></Typography>
            <Paragraph>
                Działalność prowadzimy na terenie województwa Śląskiego i okolic:
            </Paragraph>
            <br/>
            <Grid container spacing={2}>
                {cities.map((entry) =>
                    <Grid item sm={4} xs={12}>
                        <Typography fontWeight={600}>{entry}</Typography>
                    </Grid>
                )}
            </Grid>
        </>

    return (
        <CustomPage
            currentPageName={currentPageName}
            content={content}
        />
    )
}
