import React from "react";
import split from "../../assets/images/split.png";
import multi from "../../assets/images/multi.png";
import system from "../../assets/images/system.png";

export const installationTexts: string[] = [
    'Firma JDK specjalizuje się w montażu zarówno klimatyzacji jak i nowoczesnych systemów klimatyzacyjnych w domach, mieszkaniach, biurach czy lokalach użytkowych.',
    'W zależności od potrzeb i oczekiwań możemy zaproponować montaż klimatyzatorów typu SPLIT, MULTISPLIT lub rozbudowanych systemów typu VRF czy VRV itp. Oferowane przez nas klimatyzatory posiadają gwarancję, a naszym Klientom zapewniamy serwis gwarancyjny i pogwarancyjny.',
    'Firma JDK zapewnia: doradztwo, najwyższą jakość usług, konkurencyjne i niskie ceny a także szybkie terminy realizacji. Nasi pracownicy posiadają doświadczenie, wysokie kwalifikacje oraz wszystkie niezbędne uprawnienia i certyfikaty.',
    'Prace montażowe realizujemy na terenie woj. śląskiego i opolskiego.'
]

export const servicesTexts: string[] = [
    'Firma JDK oferuje nie tylko montaż klimatyzatorów ale także świadczy usługi serwisowe w zakresie przeprowadzenia przeglądów technicznych, konserwacji oraz napraw.',
    'Posiadamy autoryzację wielu firm tj.: DAIKIN, SINCLAIR, FUJITSY, ALPICAIR, MITSUBISHI, LG czy ROTENSO, które dają nam uprawnienia do wykonywania wszelkich prac serwisowych. Gwarantujemy najwyższą jakość oferowanych przez nas usług, zachowując gwarancje producenta.',
    // 'Posiadamy wykwalifikowanych Pracowników, odpowiedni sprzęt oraz współpracując z Fundacją Prozon, jesteśmy w stanie zapewnić kompleksową obsługę urządzeń klimatyzacyjnych, zgodnie z aktualnymi wymogami przepisów dotyczących substancji zubożających warstwę ozonową oraz zapobiegania emisji gazów cieplarnianych.',
]

interface ITableEntryProps {
    header: JSX.Element
    photo: string
    description: string
}

export const tableEntries:ITableEntryProps[] = [
    {
        header: <>Klimatyzator<br/>SPLIT</>,
        photo: split,
        description: 'W skład klimatyzatora split wchodzi jedna jednostka zewnętrzna i jedna jednostka wewnętrzna'
    },
    {
        header: <>Klimatyzator<br/>MULTISPLITSPLIT</>,
        photo: multi,
        description: 'W skład klimatyzatora multisplit wchodzi jedna jednostka zewnętrzna i maksymalnie pięć jednostek wewnętrznych'
    },
    {
        header: <>Systemy<br/>klimatyzacyjne</>,
        photo: system,
        description: 'Są to rozbudowane systemy klimatyzacyjne które można zamontować w różnej konfiguracji.'
    },
]


