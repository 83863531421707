import React from "react";
import {PagesDataConsts} from "../../../logic/consts/PagesDataConsts";
import {CustomPage} from "../../../components/custom_page/CustomPage";
import {Grid} from "@mui/material";
import {companyCardsData} from "../rangeContent";
import {CompanyCard} from "../../../components/CompanyCard";
import {Paragraph} from "../../../components/Paragraph";

export const AirConditioning: React.FC = () => {
    const currentPageName = PagesDataConsts.AIR_CONDITIONING.name

    const content =
        <>
            <Paragraph>
                Chcąc spełnić oczekiwania Klientów, w swojej ofercie posiadamy urządzenia proste w
                obsłudze, ciche i energooszczędne renomowanych firm takich jak:
            </Paragraph>
            <Grid container
                  spacing={2}
                  sx={{}}
            >
                {companyCardsData.map((itemData) => (
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <CompanyCard
                            {...itemData}
                        />
                    </Grid>
                ))}
            </Grid>
        </>


    return (
        <CustomPage
            currentPageName={currentPageName}
            content={content}
        />
    )
}