import alnor_img from "../../../../assets/images/company_logos/ventilation/alnor.svg";
import systemair_img from "../../../../assets/images/company_logos/ventilation/systemair.svg";
import tywent_img from "../../../../assets/images/company_logos/ventilation/tywent.svg";
import ventia_img from "../../../../assets/images/company_logos/ventilation/ventia.webp";
import vents_group_img from "../../../../assets/images/company_logos/ventilation/vents_group.png";
import venture_industries_img from "../../../../assets/images/company_logos/ventilation/venture_industries.png";

import {ICompanyCardProps} from "../../../../components/CompanyCard";

export const ventilationInfo =
    "W swojej ofercie posiadamy również urządzenia wentylacyjne renomowanych firm tj.: VENTURE INDUSTRES, " +
    "SYSTEMAIR, TYWENT, VENTIA, ALNOR, VENTS GROUP. W naszym asortymencie znajdziecie Państw m.in. wentylatory " +
    "łazienkowe, kuchenne, kanałowe, ścienne, dachowe i promieniowe, a także wentylatory w wykonaniu specjalnym tj.: " +
    "wentylatory przeciwwybuchowe, oddymiające, czy chemoodporne. Oferujemy również sprzedaż akcesoriów niezbędnych " +
    "do instalacji wentylacyjnych np. czerpnie i wyrzutnie powietrza, tłumiki hałasu, przewody wentylacyjne, " +
    "nagrzewnice i chłodnice, kratki wentylacyjne, czy anemostaty."

export const ventilationCardsData: ICompanyCardProps[] = [
    {title: 'Venture Industries', imagePath: venture_industries_img, url: 'https://www.venture.pl/'},
    {title: 'Systemair', imagePath: systemair_img, url: 'https://www.systemair.com/pl-pl/'},
    {title: 'Tywent', imagePath: tywent_img, url: 'https://tywent.pl/'},
    {title: 'Ventia', imagePath: ventia_img, url: 'https://www.ventia.pl/pl'},
    {title: 'Vents Group', imagePath: vents_group_img, url: 'https://vents-group.pl/'},
    {title: 'Alnor', imagePath: alnor_img, url: 'https://www.alnor.com.pl/'},
]