export const cities = [
    'Bielsko-Biała',
    'Bytom',
    'Chrzanów',
    'Częstochowa',
    'Dąbrowa Górnicza',
    'Gliwice',
    'Jastrzębie Zdrój',
    'Katowice',
    'Kędzierzyn Koźle',
    'Lubliniec',
    'Mikołów',
    'Myszków',
    'Olkusz',
    'Opole',
    'Oświęcim',
    'Racibórz',
    'Ruda Śląska',
    'Rybnik',
    'Sosnowiec',
    'Strzelce Opolskie',
    'Tarnowskie Góry',
    'Tychy',
    'Wodzisław Śląski',
    'Zabrze',
    'Zawiercie',
    'Żory'
]