import {ICompanyCardProps} from "../../components/CompanyCard";
import alpicair_img from "../../assets/images/company_logos/air_conditioning/alpicair.png"
import aux_img from "../../assets/images/company_logos/air_conditioning/aux.png"
import daikin_img from "../../assets/images/company_logos/air_conditioning/daikin.svg"
import fujitsu_img from "../../assets/images/company_logos/air_conditioning/fujitsu.svg"
import kaisai_img from "../../assets/images/company_logos/air_conditioning/kaisai.jpg"
import lg_img from "../../assets/images/company_logos/air_conditioning/lg.jpg"
import mitsubishi_electric_img from "../../assets/images/company_logos/air_conditioning/mitsubishi_electric.svg"
import rotenso_img from "../../assets/images/company_logos/air_conditioning/rotenso.png"
import sinclair_img from "../../assets/images/company_logos/air_conditioning/sinclair.jpg"


export const companyCardsData: ICompanyCardProps[] = [
    {title: 'AlpicAir', imagePath: alpicair_img, url: 'https://www.alpicair.com/pl/'},
    {title: 'AUX', imagePath: aux_img, url: 'https://aux-polska.pl/'},
    {title: 'DAIKIN', imagePath: daikin_img, url: 'https://www.daikin.pl/pl_pl/customers.html'},
    {title: 'Fujitsu', imagePath: fujitsu_img, url: 'https://www.fujitsu-general.com/pl/'},
    {title: 'Kaisai', imagePath: kaisai_img, url: 'https://kaisai.com/pl/'},
    {title: 'LG', imagePath: lg_img, url: 'https://www.lg.com/pl/klimatyzacja/'},
    {
        title: 'Mitsubishi Electric',
        imagePath: mitsubishi_electric_img,
        url: 'https://pl.mitsubishielectric.com/pl/products-solutions/air-conditioning/index.html'
    },
    {title: 'Rotenso', imagePath: rotenso_img, url: 'https://rotenso.com/pl/'},
    {title: 'Sinclair', imagePath: sinclair_img, url: 'https://www.sinclair.pl/'},
]