import {Button, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import {useNavigate} from "react-router-dom";
import {SvgIconProps} from "@mui/material/SvgIcon/SvgIcon";

export interface INavigationBlockProps {
    name: string
    route: string
    icon?: React.ReactElement<SvgIconProps>
}

export const NavigationBlock: React.FC<INavigationBlockProps> = (props) => {
    const {name, route, icon} = props

    const navigate = useNavigate()

    return (
        <Grid item
              sx={{}}
        >
            <Button
                variant='contained'
                disableElevation
                onClick={() => navigate(route)}
                sx={{
                    textTransform: 'none',
                    backgroundColor: "primary.main",
                    width: {sm: 300, xs: 180},
                    height: {sm: 185.41, xs: 111.25},
                }}
            >
                {icon}
                <Typography color="white" fontSize={{sm: 30, xs: 20}} style={{marginLeft: 10, marginRight: 10}}>{name}</Typography>
                {/*{icon}*/}
            </Button>
        </Grid>
    )
}