import {styled} from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import {DRAWER_WIDTH} from "./drawer";
import {AppBarProps as MuiAppBarProps} from "@mui/material/AppBar/AppBar";

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

export const CustomAppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: DRAWER_WIDTH,
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));