import React from "react";
import {PagesDataConsts} from "../../../logic/consts/PagesDataConsts";
import {CustomPage} from "../../../components/custom_page/CustomPage";
import {PageInProgress} from "../../../components/PageInProgress";

export const HeatPumps: React.FC = () => {
    const currentPageName = PagesDataConsts.HEAT_PUMPS.name

    const content = <PageInProgress/>

    return (
        <CustomPage
            currentPageName={currentPageName}
            content={content}
        />
    )
}