import React, {useContext} from "react";
import {CustomPageContainer} from "./CustomPageContainer";
import {useTheme} from "@mui/material/styles";
// Optional for certificate
// import SchoolIcon from '@mui/icons-material/School';
// import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import {DrawerOpenedContext} from "../../logic/context/DrawerOpenedContext";
import {IPageData, PagesDataConsts} from "../../logic/consts/PagesDataConsts";

interface ICustomPageProps {
    currentPageName: string
    content: JSX.Element
}

export const CustomPage: React.FC<ICustomPageProps> = (props) => {
    const {currentPageName, content} = props

    const theme = useTheme();

    const {drawerOpened, openDrawer, closeDrawer} = useContext(DrawerOpenedContext)
    const handleDrawerOpen = () => openDrawer()
    const handleDrawerClose = () => closeDrawer()

    const drawerItemsLists: IPageData[][] = [
        [
            PagesDataConsts.HOME,
            PagesDataConsts.ABOUT,
            PagesDataConsts.SERVICES,
        ],
        [
            PagesDataConsts.RANGE,
            PagesDataConsts.AIR_CONDITIONING,
            PagesDataConsts.VENTILATION,
            PagesDataConsts.HEAT_PUMPS,
        ],
        [
            PagesDataConsts.CERTIFICATES,
            PagesDataConsts.CONTACT_US,
        ]
    ]

    return (
        <CustomPageContainer
            currentPageName={currentPageName}
            theme={theme}
            open={drawerOpened}
            handleDrawerOpen={handleDrawerOpen}
            handleDrawerClose={handleDrawerClose}
            drawerItemsLists={drawerItemsLists}
            content={content}
        />
    )
}