import React from 'react';
import './App.css';
import {BrowserRouter, Routes} from "react-router-dom";
import routes from "./config/routes";
// import {createTheme, ThemeProvider, useMediaQuery} from "@mui/material";
import {DrawerOpenedProvider} from "./logic/context/DrawerOpenedContext";
import {ScrollToTop} from "./components/ScrollToTop";


function App() {

    // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: light');
    //
    // const theme = React.useMemo(
    //     () =>
    //         createTheme({
    //             palette: {
    //                 mode: prefersDarkMode ? 'light' : 'dark',
    //             },
    //         }),
    //     [prefersDarkMode],
    // );

    return (
        // <ThemeProvider theme={theme}>
            <DrawerOpenedProvider>
                <BrowserRouter>
                    <ScrollToTop />
                    <Routes>
                        {routes}
                    </Routes>
                </BrowserRouter>
            </DrawerOpenedProvider>
        // </ThemeProvider>
    )
}

export default App;
