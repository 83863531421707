import React from "react";
import logo from "../../assets/images/logo.png";
import Typography from "@mui/material/Typography";
import {CustomPage} from "../../components/custom_page/CustomPage";
import {PagesDataConsts} from "../../logic/consts/PagesDataConsts";
import {Paragraph} from "../../components/Paragraph";
import {aboutText, advantagesList} from "./aboutTexts";
import {List, ListItem} from "@mui/material";

export const About: React.FC = () => {
    const currentPageName = PagesDataConsts.ABOUT.name

    const content =
        <>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                // height: '100vh',
            }}>
                <img
                    src={logo}
                    alt="Logo"
                    // width="100vw"
                    style={{
                        maxWidth: "100%",
                        height: "auto",
                        width: "auto/9"
                        // resize:"hor",
                    }}
                />
            </div>
            {aboutText.map((entry) => <Paragraph>{entry}</Paragraph>)}
            <br/>
            <Typography fontWeight={600} fontSize={20}>Dlaczego warto z nami współpracować:</Typography>
            <List sx={{ listStyleType: 'disc', pl: 4 }}>
                {advantagesList.map(entry => <ListItem sx={{ display: 'list-item' }}>{entry}</ListItem>)}
            </List>
        </>

    return (
        <CustomPage
            currentPageName={currentPageName}
            content={content}
        />
    )
}