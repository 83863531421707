import React from "react";
import Typography from "@mui/material/Typography";
import {CustomPage} from "../../components/custom_page/CustomPage";
import {PagesDataConsts} from "../../logic/consts/PagesDataConsts";
import {installationTexts, servicesTexts, tableEntries} from "./serivcesTexts";
import {Box, Card, CardContent, CardHeader, CardMedia, Grid} from "@mui/material";
import {Paragraph} from "../../components/Paragraph";
import {ACTypeDetailsCard} from "./ACTypeDetailsCard";
import service_img from "../../assets/images/service.png";


export const Services: React.FC = () => {
    const currentPageName = PagesDataConsts.SERVICES.name

    const content =
        <Box sx={{paddingLeft: {md: 20, xs: 0}, paddingRight: {md: 20, xs: 0}}}>
            <Typography
                paragraph
                align={'center'}
                fontWeight={600}
                sx={{
                    fontSize: {sm: 30, xs: 25}
                }}
            >
                Montaż klimatyzacji
            </Typography>
            {installationTexts.map((text) => <Paragraph>{text}</Paragraph>)}

            <Grid container justifyContent='center' sx={{width: '100%'}} spacing={2}>
                {
                    tableEntries.map((entry) =>
                        <Grid item sm={12 / tableEntries.length} xs={12} style={{paddingLeft: 0, paddingRight: 0}}>
                            <ACTypeDetailsCard
                                title={entry.header}
                                subheader={entry.description}
                                imagePath={entry.photo}
                            />
                        </Grid>
                    )
                }
            </Grid>
            <br/>
            <br/>

            <Typography
                paragraph
                align={'center'}
                fontWeight={600}
                sx={{
                    fontSize: {sm: 30, xs: 25}
                }}
            >
                Serwis klimatyzacji
            </Typography>
            {servicesTexts.map((text) => <Paragraph>{text}</Paragraph>)}
            <Paragraph>
                Posiadamy wykwalifikowanych Pracowników, odpowiedni sprzęt oraz współpracując
                z <a href='https://prozon.org.pl/' target="_blank" rel="noopener noreferrer">Fundacją Prozon</a>,
                jesteśmy w stanie zapewnić kompleksową obsługę urządzeń
                klimatyzacyjnych, zgodnie z aktualnymi wymogami przepisów dotyczących
                substancji zubożających warstwę ozonową oraz zapobiegania emisji gazów
                cieplarnianych.
            </Paragraph>


            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <Card
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    sx={{
                        maxWidth: {lg: 300, md: 300, sm: 250, xs: 250},
                        height: {lg: 300, md: 300, sm: 250, xs: 250},
                        width: '100%'
                    }}
                >
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        width: '100%',
                        textAlign: 'center',
                    }}>
                        <CardMedia
                            style={{
                                objectFit: 'contain',
                            }}
                            component="img"
                            image={service_img}
                            alt="Serwis klimatyzacji"
                            sx={{
                            }}
                        />

                    </div>
                </Card>
            </div>
        </Box>

    return (
        <CustomPage
            currentPageName={currentPageName}
            content={content}
        />
    )
}