import React from 'react'
import {PagesDataConsts} from "../../../../logic/consts/PagesDataConsts";
import {CustomPage} from "../../../../components/custom_page/CustomPage";
import {Grid} from "@mui/material";
import {ventilationCardsData, ventilationInfo} from "./ventilationTexts";
import {Paragraph} from "../../../../components/Paragraph";
import {CompanyCard} from "../../../../components/CompanyCard";

export const Ventilation: React.FC = () => {
    const currentPageName = PagesDataConsts.VENTILATION.name

    const content =
        <>
            <Paragraph>
                {ventilationInfo}
            </Paragraph>
            <Grid container
                  spacing={2}
                  sx={{}}
            >
                {ventilationCardsData.map((itemData) => (
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <CompanyCard
                            {...itemData}
                        />
                    </Grid>
                ))}
            </Grid>
        </>

    return (
        <CustomPage
            currentPageName={currentPageName}
            content={content}
        />
    )
}
