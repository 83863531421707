import React from "react";
import {CustomPage} from "../../components/custom_page/CustomPage";
import Typography from "@mui/material/Typography";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from '@mui/icons-material/Mail';
import FacebookIcon from '@mui/icons-material/Facebook';
import {Grid} from "@mui/material";
import Box from "@mui/material/Box";
import {PagesDataConsts} from "../../logic/consts/PagesDataConsts";
import {ContactsMap} from "./ContactsMap";


export const ContactUs: React.FC = () => {
    const currentPageName = PagesDataConsts.CONTACT_US.name

    const content =
        <>
            <p style={{placeSelf: "center"}}>
                <Grid container>
                    <Grid item md={6} xs={12}>
                        <Grid
                            container
                            spacing={2}
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            style={{minHeight: '30vh'}}
                        >
                            <Grid item>
                                <Typography variant='h5' fontWeight={600} paragraph>Dane kontaktowe:</Typography>
                            </Grid>
                            <Grid item>
                                <Box sx={{width: 280}} borderRadius={5}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={2}><PhoneIcon sx={{color: 'primary.main'}}/></Grid>
                                        <Grid item xs={9}><Typography color='black'>+48 601 507 454</Typography></Grid>

                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={2}><PhoneIcon sx={{color: 'primary.main'}}/></Grid>
                                        <Grid item xs={9}><Typography color='black'>+48 515 165 676</Typography></Grid>

                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={2}><MailIcon sx={{color: 'primary.main'}}/></Grid>
                                        <Grid item xs={9}><Typography
                                            color='black'>jdkklimatyzacja@gmail.com</Typography></Grid>

                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={2}><FacebookIcon sx={{color: '#3b5998'}}/></Grid>
                                        <Grid item xs={9}><Typography color='black'><a
                                            href="https://www.facebook.com/jdkklima">JDK Krzysztof
                                            Murzyn</a></Typography></Grid>
                                    </Grid>
                                </Box>
                                <Grid item xs={4}/>
                            </Grid>
                            <br/>
                        </Grid>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Grid
                            container
                            spacing={2}
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            style={{minHeight: '30vh'}}
                        >
                            <Grid item>
                                <Typography variant='h5' fontWeight={600} paragraph>Dane rejestrowe:</Typography>
                            </Grid>
                            <Grid item>
                                <Box sx={{width: 250}}  borderRadius={5}>

                                    <Grid container
                                          spacing={2}
                                          justifyContent="center"
                                    >
                                        <Grid item xs={6}>
                                            <Typography color='primary.main' fontWeight={600}>Nazwa pełna</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography color='black'>Krzysztof Murzyn JDK</Typography>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Typography color='primary.main' fontWeight={600}>Adres do doręczeń</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography color='black'>ul. Traugutta 54a, 42-674 Zbrosławice</Typography>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Typography color='primary.main' fontWeight={600}>NIP</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography color='black'>6482031136</Typography>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Typography color='primary.main' fontWeight={600}>REGON</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography color='black'>240767900</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Grid item xs={4}/>
                            </Grid>
                            <br/>
                        </Grid>
                    </Grid>

                </Grid>
                <ContactsMap/>

            </p>
        </>

    return (
        <CustomPage
            currentPageName={currentPageName}
            content={content}
        />
    )
}