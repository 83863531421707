import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import * as React from "react";
import {Typography} from "@mui/material";
import {TypographyProps} from "@mui/material/Typography/Typography";

interface IDrawerListItemButtonProps {
    open: boolean
    name: string
    icon?: JSX.Element
    selected: boolean
    onClick: () => void
    textProps?: TypographyProps
}

export const DrawerListItemButton: React.FC<IDrawerListItemButtonProps> = (props) => {
    const {open, name, icon, selected, onClick, textProps} = props

    return (
        <ListItemButton
            key={name}
            selected={selected}
            onClick={onClick}
        >
            <ListItemIcon
                sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                }}
            >
                {icon}
            </ListItemIcon>
            <ListItemText sx={{opacity: open ? 6 : 0}}>
                <Typography {...textProps}>
                    {name}
                </Typography>
            </ListItemText>
        </ListItemButton>
    )
}